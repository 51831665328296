@font-face {
    font-family: 'FontAwesome';
    src: url('../fonts/fontawesome-webfont.eot?v=4.6.3');
    src: url('../fonts/fontawesome-webfont.eot?#iefix&v=4.6.3')
            format('embedded-opentype'),
        url('../fonts/fontawesome-webfont.woff2?v=4.6.3') format('woff2'),
        url('../fonts/fontawesome-webfont.woff?v=4.6.3') format('woff'),
        url('../fonts/fontawesome-webfont.ttf?v=4.6.3') format('truetype'),
        url('../fonts/fontawesome-webfont.svg?v=4.6.3#fontawesomeregular')
            format('svg');
    font-weight: normal;
    font-style: normal;
}

.fa {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.fa-star:before {
    content: '';
}

.fa-star-o:before {
    content: '';
}

.fa-plus:before {
    content: '';
}

.fa-minus:before {
    content: '';
}

.fa-facebook-f:before,
.fa-facebook:before {
    content: '';
}

.fa-twitter:before {
    content: '';
}

.fa-google-plus:before {
    content: '';
}
.fa-linkedin:before {
    content: '';
}

.fa-instagram:before {
    content: '';
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
    position: static;
    width: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    clip: auto;
}
