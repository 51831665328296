
.submitQue {
    margin-right: 95px;
}

a.skipForNow {
    right: 0 !important;
    position: absolute;
    top: 10px;
    /* margin-left: 15px; */
    margin-right: 20px;
    text-decoration: underline;
    color: #8c8ca3;
}

a.skipForNow:hover {
    color: rgb(220, 183, 37);
}

.unitdropdownIcon {
    right: 22px !important;
}

.submitbtn {
    padding: 10px !important;
    background-color: #09b7d9 !important;
}

// .devideDiv {
//     cursor: pointer;
// }

.devideDiv svg.starIcon {
    color: rgb(255, 197, 3) !important;
}

.devideDiv svg {
    color: rgb(197, 2, 2) !important;
    font-size: 15px;
    // cursor: pointer;
}

/* .otherCardDiv{
     margin-left: -70px;
} */
.categoriDiv h4 {
    font-weight: 800 !important;
    color: #045be3 !important;
    font-size: 15px !important;
}

svg.unFav {
    color: #434343 !important;
}

.multiselsearchBox {
    height: 40px;
}

.p-multiselect-filter-container svg {
    top: 10px !important;
}

.p-multiselect-item .p-checkbox .p-checkbox-box {
    width: 20px !important;
    height: 20px !important;
}

.p-multiselect-panel .p-multiselect-header>div.p-checkbox {
    display: block !important;
}

// .devideDiv svg ,.devideDiv{
//    pointer-events: none !important;
//    cursor: none !important;
// }

