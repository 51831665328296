
.p-multiselect {
    border-radius: 5px !important;
}

.unitdropdownIcon {
    right: 30px !important;
}

.optionChild {
    padding: 5px !important;
}
.snpt-row-top{
   padding-top: 0px !important;
}
.supplierCardDiv .sl-content{
    padding: 0px !important;
}
.suppcardDiv .v-card{
    margin-top: 15px !important;
}
.cardText{
    text-align: justify;
    font-size: 1.2rem !important;
    width: 250px;
}
#checksupp{
    width: 20px !important;
    height: 19px !important;
    // margin-top: 5px !important;
    cursor: pointer;
    margin-top: -3px;
    float: right;
    accent-color: #04eefa !important;
}
.supplierCardDiv .v-card-title{
    // padding: 5px;
    font-size: 1.5rem !important;
    padding: 0.8rem;
}
.supplierCardDiv .v-card-text{
    padding: 1.5rem !important;
}
.supplierCardDiv .v-card-item{
    background-color: #112785 !important;
}
// #checksupp[type="checkbox"]:before {
//   position: relative;
//   display: block;
//   width: 11px;
//   height: 11px;
//   border: 1px solid #808080;
//   content: "";
//   background: #FFF;
// }

// #checksupp[type="checkbox"]:after {
//   position: relative;
//   display: block;
//   left: 2px;
//   top: -11px;
//   width: 7px;
//   height: 7px;
//   border-width: 1px;
//   border-style: solid;
//   border-color: #B3B3B3 #dcddde #dcddde #B3B3B3;
//   content: "";
//   background-image: linear-gradient(135deg, #B1B6BE 0%, #FFF 100%);
//   background-repeat: no-repeat;
//   background-position: center;
// }

