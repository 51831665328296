
#sublist:hover .sublistDiv {
    display: block;
}

.separateDiv {
    border-bottom: 1px solid #bfbfbf;
}

.dropdownIcom {
    top: 35px !important;
}

.select__arrow {
    right: 30px !important;
}
