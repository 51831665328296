
body {
    font-family: 'Quicksand', sans-serif;
}

.catsubprodDiv {
    border: 1px solid #ababab;
    padding: 1rem;
    border-radius: 1rem;
}

.infoText {
    top: 34px;
}

.infoText span {
    color: rgb(68, 0, 255);
    margin-left: -125px !important;
}

.unitdropdownIcon {
    right: 25px !important;
}

.singleSelectdropdown {
    border-radius: 15px !important;
}

.infoBtnDiv {
    margin-top: -7px;
    margin-left: -10px !important;
}

.ImageDiv label,
.infoBtnDiv input {
    padding: 5px 5px !important;
}

.termconditionDiv .statementDiv .content {
    margin-top: -7px !important;
}

.agreementDiv .contentDiv span {
    font-size: 12px;
    color: #545454;
}

// .agreementDiv .contentDiv.childDiv{
//     margin-top:-15px;
// }
.profileApproved span {
    font-size: 14px !important;
    color: #025802 !important;
    font-weight: 800 !important;
}

.profileApproved p {
    // font-size: 17px !important;
    color: #025802 !important;
    margin-left: 13px // font-weight: 800 !important;
}

.profileDisapproved span {
    font-size: 14px !important;
    color: #580202 !important;
    font-weight: 800 !important;
}

.profileDisapproved p {
    color: #580202 !important;
    margin-left: 13px
}

.profileDisapproved {
    // width: 300px;
    overflow-y: auto;
    margin-right: 20px;
}
.policyInfoDiv p a{
    color: #d90505;
    text-decoration: underline !important;
    cursor: pointer !important;
}
.disapprovedClass{
    margin-top:-20px;
}
.adminDiv{
    margin-top: -12px !important;
}
.submitQue{
    margin-right: 95px;
}
a.skipForNow{
    right: 0 !important;
    position: absolute;
    top: 10px;
    /* margin-left: 15px; */
    margin-right: 20px;
    text-decoration: underline;
    color: #8c8ca3;
}
a.skipForNow:hover{
    color: rgb(220, 183, 37);
}
.opratorIcon{
    font-size: 15px !important;
}
.devideDiv svg{
    color: rgb(197, 2, 2) !important;
    font-size: 15px;
    cursor: pointer;
}
.devideDiv svg.starIcon{
    color: rgb(255,197, 3) !important;
}
svg.orderIcon{
    color: rgb(53, 187, 4) !important;
}
