
body {
    font-family: 'Quicksand', sans-serif;
}

#priceunitattr {
    padding-left: 2.5rem !important;
}

.pricingDiv {
    top: 0px !important;
    position: absolute;
    padding: 1rem;
}

.pricingDiv span>i {
    color: #aaa8ab !important;
}

.unitdropdownIcon {
    right: 30px !important;
}

.singleSelectdropdown {
    border-radius: 15px !important;
}
.amountChangedropdown{
    height: 17px !important;
    border-radius: 15px !important;
}
.amtdownarrow{
    right: 55px !important;
    top: 8px !important;
    border-width: 5px 4px 0 5px !important;
}


.changeCharges {
    // right: 15px;
    border: 1px #cdcdcd;
    height: 39px;
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
    background-color: #cdcdcd;
    padding: 13px;
    cursor: pointer;
}

.changeCharges span>i {
    color: #000 !important;
}

.chargesCountDiv {
    display: inline-flex;
}

.chargecountinp {
    width: 50% !important;
}

.chargecountinp:nth-child(1) {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}

.chargecountinp:last-of-type {
    border-left: 0;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
}
.chargesinfobox{
    margin-right: 10% !important;
}
