#homepage-1 {
    .ps-home-banner {
        padding-top: 30px;
        margin-bottom: 30px;
        .swiper-container {
            height: 100%;
            .swiper-slide {
                height: 100%;
                .ps-banner {
                    height: 100%;
                }
            }
        }

        .ps-container {
            display: flex;
            flex-flow: row nowrap;

            > * {
                width: 100%;
            }

            .ps-section__left {
                padding-right: 30px;
                max-width: calc(100% - 390px);
            }

            .ps-section__right {
                max-width: 390px;

                .ps-collection {
                    display: block;
                    margin-bottom: 30px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }

            @include media('<lg') {
                display: block;
                .ps-section__left {
                    max-width: 100%;
                    margin-bottom: 10px;
                    padding-right: 0;
                }

                .ps-section__right {
                    display: flex;
                    flex-flow: row nowrap;
                    margin: 0;
                    max-width: 100%;
                    .ps-collection {
                        width: 100%;
                        max-width: 50%;
                        padding: 0 5px;

                        img {
                            width: 100%;
                        }
                        &:first-child {
                            padding-left: 0;
                        }
                        &:last-child {
                            padding-right: 0;
                        }
                    }
                }
            }
        }
        @media screen and (max-width: 1200px) {
            .ps-banner {
                min-height: 400px;
            }
        }
        @media screen and (max-width: 480px) {
            .ps-banner {
                min-height: 200px;
            }
        }
    }

    .ps-site-features {
        padding-bottom: 90px;
    }

    .ps-deal-of-day {
        padding-bottom: 80px;
        .slick-slide {
            > div {
                margin: 0 15px;
            }
        }
        .ps-product {
            .ps-product__price {
                color: #690;
                font-size: 18px;
                font-weight: 600;

                del {
                    margin-left: 4px;
                    font-size: 14px;
                    color: red;
                }

                small {
                    margin-left: 4px;
                    color: red;
                }
            }
            .ps-product__content {
                .ps-product__rating {
                    display: block !important;
                }
            }
        }
    }

    .ps-top-categories {
        padding: 80px 0 50px;

        h3 {
            text-transform: capitalize;
        }

        @media (min-width: 1440px) {
            .row {
                .col-xl-2 {
                    max-width: calc(100% / 8);
                }
            }
        }
        @include media('<lg') {
            padding: 60px 0;
        }

        @include media('<md') {
            padding: 50px 0;
        }

        @include media('<sm') {
            padding: 40px 0;
        }

        @include media('<xs') {
            padding: 35px 0;
        }
    }

    .ps-product-list {
        padding-bottom: 80px;
        @include media('<md') {
            padding-bottom: 50px;
        }
        @include media('<sm') {
            padding-bottom: 40px;
        }
        @include media('<xs') {
            padding-bottom: 0px;
        }
    }

    .ps-download-app {
        margin-top: 80px;
        margin-bottom: 100px;
        @include media('<sm') {
            margin-bottom: 50px;
        }
    }
}

#shop-carousel {
    .ps-carouse--nav-inside {
        margin-bottom: 60px;
        @include media('<sm') {
            margin-bottom: 40px;
        }
    }

    .ps-block--container-hightlight {
        margin-bottom: 60px;
    }

    .ps-product-list {
        margin-bottom: 90px;

        .ps-section__header {
            display: block;
            text-align: center;
        }

        @include media('<sm') {
            margin-bottom: 50px;
        }
    }
}

#shop-categories {
    background-color: #f5f5f5;
    padding-bottom: 90px;

    .ps-catalog-top {
        padding: 70px 0;
    }

    .ps-product-list {
        margin-bottom: 20px;
        padding: 20px 20px 50px;
        background-color: #ffffff;

        .ps-section__header {
            h3 {
                font-weight: 400;
            }
        }
    }

    .ps-more-categories {
        .ps-section__content {
            display: flex;
            flex-flow: row wrap;

            .ps-block--category-vertical {
                margin-bottom: 30px;
                max-width: 20%;
                padding: 30px 10px;
                @include media('<lg') {
                    max-width: 25%;
                }
                @include media('<md') {
                    max-width: calc(100% / 3);
                }
                @include media('<sm') {
                    max-width: 50%;
                }
                @include media('<xs') {
                    max-width: 100%;
                }
            }
        }
    }
}

#shop-sidebar {
    .ps-page__header {
        margin-bottom: 50px;

        h1 {
            margin-bottom: 20px;
            font-weight: 700;
            color: $color-heading;
            font-size: 24px;
        }
    }

    .ps-layout--shop {
        padding-top: 30px;
    }
}

#contact-us {
    .ps-breadcrumb {
        margin-bottom: 0;
    }
}

#contact-map {
    height: 100vh;
}

#shop-carousel {
    .ps-carousel--nav-inside {
        padding: 30px 0 50px;

        .owl-item {
            img {
                width: 100%;
            }
        }

        @include media('<sm') {
            padding: 30px 0;
        }
    }
}

#search-result {
    .ps-breadcrumb {
        margin-bottom: 30px;
    }
}

.ps-page--404 {
    position: relative;
    min-height: 100vh;
    background-color: #efeef0;
    text-align: center;

  

    img {
        margin-bottom: 100px;
    }

    h3 {
        margin-bottom: 20px;
        font-size: 36px;
        color: $color-heading;
        font-weight: 600;
    }

    p {
        margin-bottom: 20px;
        font-size: 18px;

        a {
            color: $color-1st;
        }
    }

    .ps-form--widget-search {
        max-width: 370px;
        margin: 0 auto;

        input {
            height: 50px;
            border-color: #fff;
        }
    }

    @include media('<sm') {
        h3 {
            font-size: 24px;
        }
        p {
            font-size: 14px;
        }
    }
}

.ps-page--comming-soon {
    text-align: center;
    padding-bottom: 50px;
    @extend %list-reset;

    .ps-countdown {
        li {
            display: inline-block;
            margin-right: 20px;

            span {
                margin-bottom: 0;
                font-weight: 400;
                font-size: 48px;
                color: $color-heading;
                line-height: 1em;
            }

            p {
                margin-bottom: 0;
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .ps-page__header {
        padding: 50px 0;

        .ps-logo {
            display: inline-block;
            margin-bottom: 50px;
        }

        h1 {
            margin-bottom: 20px;
            font-size: 36px;
            font-weight: 600;
        }
    }

    figure {
        figcaption {
            margin-bottom: 30px;
            font-size: 16px;
            font-weight: 600;
        }

        .ps-countdown {
            margin-bottom: 80px;
        }
    }

    .ps-list--social {
        li {
            margin-right: 30px;

            a {
                color: #cccccc;

                &:hover {
                    color: $color-1st;
                }
            }
        }
    }
}

.ps-page--shop {
    .ps-shop-banner {
        padding: 30px 0 45px;
    }

    .ps-shop-brand {
        margin-bottom: 45px;
    }

    .ps-shop-categories {
        margin-bottom: 30px;
    }

    .ps-carousel--nav-inside {
        .owl-item {
            img {
                width: 100%;
            }
        }
    }

    @media (max-width: 1199px) {
        .ps-product:not(.ps-product--wide) {
            border-color: #dfdfdf;

            .ps-product__content:not(.hover) {
                position: relative;
                display: block;
                left: 0;
                //width: calc(100% + 40px);
                padding: 0 10px 20px;
                border-bottom: 1px solid #dfdfdf;
                border-top: none;
            }
        }

        .ps-shop-banner,
        .ps-shop-brand,
        .ps-shop-categories,
        .ps-shop-categories,
        .ps-block--shop-features,
        .ps-block--shop-features {
            display: none;
        }
    }
    @include media('<sm') {
        .ps-product {
            .ps-product__vendor {
                padding-left: 10px;
                padding-right: 10px;
            }

            .ps-product__content:not(.hover) {
                width: 100%;
                left: 0;
                padding: 0 10px 20px;
            }
        }
    }

    @media (min-width: 576px) and (max-width: 992px) {
      
    }
}

.ps-page--product {
    padding-top: 30px;

    .ps-page__container {
        display: flex;
        flex-flow: row nowrap;
        width: 100%;

        > * {
            width: 100%;
        }

        .ps-page__left {
            padding-right: 30px;
            max-width: calc(100% - 290px);
        }

        .ps-page__right {
            min-width: 290px;
            max-width: 290px;
        }
    }

    .ps-page__right {
        .widget_same-brand {
            border-bottom: 1px solid #eaeaea;
        }
    }

    &.reverse {
        .ps-page__container {
            flex-flow: row-reverse nowrap;
        }

        .ps-page__right {
            padding-right: 30px;
        }
    }

    @include media('<lg') {
        .ps-page__container {
            flex-flow: column wrap;

            .ps-page__left {
                max-width: 100%;
                padding-right: 0;
            }
            .ps-page__right {
                display: none;
            }
        }
    }
}

.ps-page--product-box {
    padding-bottom: 30px;
    background-color: #f1f1f1;
}

.ps-page--blog {
    .ps-page__header {
        padding: 90px 0;
        text-align: center;

        h1 {
            margin: 0 0 20px;
            font-size: 48px;
            font-weight: 600;
            line-height: 1em;
        }
    }

    @include media('<md') {
        .ps-page__header {
            padding: 45px 0;

            h1 {
                font-size: 36px;
            }
        }
    }
    @include media('<sm') {
        .ps-page__header {
            padding: 30px 0;

            h1 {
                font-size: 32px;
            }
        }
    }
    @include media('<xs') {
        .ps-page__header {
            padding: 25px 0;

            h1 {
                font-size: 24px;
            }
        }
    }
}

.ps-page--account {
    padding-top: 50px;
    .ps-page__left {
        height: 100%;
        padding: 30px 20px;
        border-radius: 4px;
        background-color: #fff;
    }

    .ps-page__dashboard {
        p {
            font-size: 16px;
            a {
                color: $color-linkedin;
                &:hover {
                    color: $color-1st;
                }
            }
        }
    }
}
