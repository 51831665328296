
@import '@/assets/scss/base/_reset.scss';
@import "@/assets/scss/vendor/bourbon/_bourbon.scss";
@import "@/assets/scss/utils/_mixin.scss";
@import "@/assets/scss/vendor/_media_query.scss";
@import "@/assets/scss/utils/_variable.scss";

.header--1 {
    .header__top {
        background-color: $color-1st;
    }
}
