
body {
    font-family: 'Quicksand', sans-serif;
}

.prodListHead {
    color: #740490;
}

.ProductListing .v-list-item__prepend>svg,
.ProductListing .v-list-item__content>.v-list-item-title {
    font-size: 15px;
    color: #770367;
}

.select select:disabled {
    opacity: 1.5 !important;
}

#priceunitattr {
    padding-left: 2.5rem !important;
}

.pricingDiv {
    top: 0px !important;
    position: absolute;
    padding: 1rem;
}

.pricingDiv span>i {
    color: #aaa8ab !important;
}

.select__arrow {
    right: 15px;
}

.unitdropdownIcon {
    right: 25px !important;
}

.singleSelectdropdown {
    border-radius: 15px !important;
}
.text-secondary{
    color: #979797 !important;
}
.v-expansion-panel{
    border-top-left-radius: 2rem !important;
    border-top-right-radius: 2rem !important;
}
// .v-expansion-panel-title h3{
//     font-size: 13px !important;
// }
