
.prodlabel {
    background: transparent !important;
}

.sl .sl-media {
    text-align: start;
    padding-left: 5px;
}

.sl .sl-content {
    flex-direction: column;
}

.cancelBtn {
    background-color: #222 !important;
    color: #fff !important;
}

.customLabel {
    background: #baf9f9 !important;
}

.datepickerDiv .v3dp__datepicker {
    background-color: #ffffff !important;
}

.cardContent {
    max-width: 560px;
    min-height: 520px;
    background-color: #ffffff;
    padding: 40px;
    overflow: hidden;
}

.homeCarouselContainer .cardContent:after {
    border: none !important;
}

.homebgContainer {
    position: relative;
    margin-top: 80px;
    background: #e6e6e6;
    height: 690px !important;
}

.homebgContainer:after {
    width: 35% !important;
}
.cancelbtn:hover{
    color: #0a0a0a !important;
    background-color: #dcdede !important;
    border-color: #ffffff !important;
    box-shadow: 0 6px 12px rgb(255 255 255 / 30%);
}
.select select:disabled{
    opacity: 2.5 !important;
}
.leftSideCardContainer .contentDiv{
    padding: 0px !important;
}
.buttonDiv{
    margin-bottom: 0px !important;
    padding-top: 15rem;
}
.unitdropdownIcon{
    right: 24px !important;
}
.dropdownIcom{
    top: 15px !important;
    right: 25px !important;
}
