
.widget-body {
    padding: 3rem !important;
}

.form-rows {
    padding-top: 7px !important;
}

.widget-footer {
    padding: 15px 0px !important;
    margin-right: 29px !important;
    margin-left: 29px !important;
}

.list-featured .desc h2,
.list-featured .desc span {
    text-wrap: nowrap !important;
}

.icon-feature-div .desc {
    padding-left: 60px !important;
}

.list-featured i {
    font-size: 25px !important;
}
