
.fontColor {
    color: red;
}

.disabledinputBox {
    border: none !important;
    margin-top: 0px !important;
    text-align: center !important;
}


