// $font-1st: 'Work Sans', sans-serif;
// $font-2nd: 'Open Sans', sans-serif;

// $document-font-size: 14px;
$document-font-size: 14px;
@font-face {
    font-family: Graphik;
    src: url("../fonts/graphikfont/GraphikRegular.otf");
  }
  * {
    font-family: Graphik;
  }
  


$font-1st: Graphik;
$font-2nd: Graphik;