.ps-skeleton {
    margin-bottom: 16px;
    width: 100%;
    border-radius: 50px;
    background-color: #e2e5e7;
    background-image: linear-gradient(
                    90deg,
                    rgba(#fff, 0),
                    rgba(#fff, 0.5),
                    rgba(#fff, 0)
    );
    background-size: 80px 100%;
    background-repeat: no-repeat;
    background-position: left -80px top 0;
    animation: shine 1s ease infinite;

    &.line {
        height: 16px;
        &:last-child {
            max-width: 75%;
        }
    }

    &.heading {
        height: 26px;
        width: 60%;
    }
    &.button {
        height: 50px;
        width: 160px;
    }

    &.image {
        border-radius: 8px;
        height: 250px;
    }

    &.image-square {
        height: 500px;
    }

    &.rounded {
        border-radius: 50px;
    }

    &.circle {
        border-radius: 50%;
    }
}

@keyframes shine {
    to {
        // Move shine from left to right, with offset on the right based on the width of the shine - see background-size
        background-position: right -80px top 0;
    }
}



