
.productRow {
    padding: 0px 15px !important;
}

.productRow .v-card {
    margin: 5px 5px !important
}
.productCard .v-card-title{
    background-color: transparent !important;
    font-size: 15px;
    color: #000 !important;
    font-weight: 600;
}
.productCard .v-card-text>.text-subtitle-1{
    font-size: 15px !important;
}
.productCard{
   display: flex; 
   gap: 2rem;
    flex-wrap: wrap;
}
.productCard .v-card{
    min-width: 130px;
    /* padding: 0 !important; */
    margin: 0 !important;
}
