
.notapprovedText {
    color: crimson;
    margin-top: -2px;
}

.approved_text {
    color: rgb(1 114 1) !important;
}

.approvalDiv {
    padding: 0.4rem;
    background: #f7cedb;
    box-shadow: 0 6px 12px rgb(0 0 0 / 10%);
    cursor: pointer;
    border-radius: 5rem;
}

.approvedDiv {
    font-size: 12px;
    background: #ffffff;
    background: #b8f9bd;
    box-shadow: 0 6px 12px rgba(0 0 0 / 10%);
    border-radius: 5rem;
    padding-left: 1rem;
    cursor: pointer;
}
