
.changebtn , .divimg{
    float: right;
    padding: 5px !important;
    border-top: none !important;
    /* margin-right: 5px !important; */
    // display: flex;
}
.proimg{
 right:0px !important;
}
.profileApproved span{
    font-size: 14px !important;
    color: #025802 !important;
    font-weight: 800 !important;
}
.profileApproved p{
    // font-size: 17px !important;
    color: #025802 !important;
    margin-left:13px
    // font-weight: 800 !important;
}
.profileDisapproved span{
    font-size: 14px !important;
    color: #580202 !important;
    font-weight: 800 !important;
}
.profileDisapproved p{
    color: #580202 !important;
    margin-left:13px
}
.profileDisapproved{
    // width: 300px;
    overflow-y: auto;
    margin-top: 0px !important;
}
.acctleftSide{
    border-right: 1px solid #ddd;
    box-shadow: 0 2px 4px 0 rgb(0,0,0 ,15%);
}
.profiletextdiv{
    margin-top: 105px !important;
    padding: 1.5rem !important;
    margin-left: 20px !important;
}
// .profileDisapproved , .profileApproved{
//     margin-top: 0px !important;
// }
