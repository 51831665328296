
.adminLoginText p{
    margin-bottom: -19px !important;
    font-size: 14px;
}
.backarrow{
  color:rgb(4 126 201) !important;
}
.adminText{
   float: right;
}
