.ps-main {

    > * {
        flex-basis: 100%;
    }

    .ps-main__sidebar {
        display: none;
    }


    @media screen and (min-width: 1200px) {
       // display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: stretch;
        min-height: 100vh;
        padding-left: 265px;
        .ps-main__sidebar {
            position: fixed;
            top: 0;
            left: 0;
            display: block;
            max-width: 300px;
            width: 100%;
            height: 100%;
            padding: 0px 0 30px 16px;
            background-color: $color-bg-sidebar;
            border-right: 2px solid #fff;
        }

    }

    @media screen and (min-width: 1440px) {
        padding-left: 320px;
        .ps-main__sidebar {
            max-width: 320px;
        }
    }

    @media screen and (min-width: 1680px) {
        .ps-main__sidebar {
            padding: 60px 0 60px 60px;
        }

        .ps-main__wrapper {
            padding: 60px 80px;
        }
    }
}

.ps-page--404 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #e4e4e4;
}

// #homepage {
//     @media screen and (max-width: 1440px) {
//         .row {
//             display: block;
//         }
//         .col-12 {

//             max-width: 100% !important;
//         }
//     }
// }
