
.dropdownDiv {
    padding: 0px 0px 0px 0px !important;
}

#productDropdown {
    color: #7b7575 !important;
}

.dropdownIcom {
    right: 25px !important;
}
