
.optionOptGroup {
    color: #122e84 !important;
    font-weight: bold;
}

.unitlabel {
    top: 12px !important;
}

.unitdropdownIcon {
    right: 24px !important;
}

.p-dropdown-panel {
    z-index: 99999 !important;
}
